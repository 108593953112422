import React from "react"
import { ThemeContext } from "../provider.js"
import Head from "../components/head"
import SEO from "../components/seo"
import styles from "./projects.module.css"
import ThemeToggle from "../components/theme-toggle"
import Navigation from "../components/navigation"
import Footer from "../components/footer"

export default function Projects() {
  return (
    <ThemeContext.Consumer>
      {context => (
        <div id="page" className={context.isDark ? "darkTheme" : ""}>
          <Head />
          <SEO title="Projects" />
          <main>

            <header id="page-header" className="maxWidth flex">
              <ThemeToggle />
              <Navigation />
            </header>

            <div id="content" className="maxWidth">
              <h1>Projects</h1>
              <div className={styles.wrapper}>

                <p>New projects are being worked on... come back later!</p>
                <p>In the meantime, feel free to check out my <a href="https://github.com/ericanorby" rel="noreferrer noopener" target="_blank">GitHub account</a>.</p>

                {/*}<div className={styles.project}>
                  <a href="https://ericanorby.github.io/picaption-react/" rel="noreferrer noopener" target="_blank">
                    <div className={styles.imgWrapper}>
                      <img className={styles.bottom} src="/images/picaption2.png" alt="Picaption screenshot" />
                      <img className={styles.top} src="/images/picaption1.png" alt="Picaption screenshot" />
                    </div>
                  </a>
                  <div>
                    <h2><a href="https://ericanorby.github.io/picaption-react/" rel="noreferrer noopener" target="_blank">Picaption</a></h2>
                    <a href="https://github.com/ericanorby/picaption-react" rel="noreferrer noopener" target="_blank">GitHub repository</a>
                  </div>
                  <p className={styles.tech}>React, Express, Node.js, MongoDB, HTML, CSS</p>
                  <p>A simple MERN app built in 2 days. A user must submit a caption on a photo before the other captions are revealed.</p>
                </div>

                <div className={styles.project}>
                  <a href="https://ericanorby.github.io/hangman/" rel="noreferrer noopener" target="_blank">
                    <div className={styles.imgWrapper}>
                      <img className={styles.bottom} src="/images/hangskeleton.png" alt="Hangskeleton screenshot" />
                      <img className={styles.top} src="/images/hangskeleton1.png" alt="Hangskeleton screenshot" />
                    </div>
                  </a>
                  <div>
                    <h2><a href="https://ericanorby.github.io/hangman/" rel="noreferrer noopener" target="_blank">Hangskeleton</a></h2>
                    <a href="https://github.com/ericanorby/hangman" rel="noreferrer noopener" target="_blank">GitHub repository</a>
                  </div>
                  <p className={styles.tech}>JS & jQuery, HTML, CSS</p>
                  <p>This hangman game was my first project at General Assembly. Building the game really solidified my passion for programming!</p>
                </div>

                <div className={styles.project}>
                  <a href="https://crumble.herokuapp.com/" rel="noreferrer noopener" target="_blank">
                    <div className={styles.imgWrapper}>
                      <img className={styles.bottom} src="/images/crumble.png" alt="Crumble screenshot" />
                      <img className={styles.top} src="/images/crumble1.png" alt="Crumble screenshot" />
                    </div>
                  </a>
                  <div>
                    <h2><a href="https://crumble.herokuapp.com/" rel="noreferrer noopener" target="_blank">Crumble</a></h2>
                    <a href="https://github.com/ericanorby/crumble" rel="noreferrer noopener" target="_blank">GitHub repository</a>
                  </div>
                  <p className={styles.tech}>Ruby on Rails, JS & jQuery, HTML, CSS</p>
                  <p>This is an application for browsing, uploading, and favoriting recipes. It was exciting to learn how to manage a database and see the project come to life along the way.</p>
                </div>

                <div className={styles.project}>
                  <a href="https://ericanorby.github.io/Venture_Forth/" rel="noreferrer noopener" target="_blank">
                    <div className={styles.imgWrapper}>
                      <img className={styles.bottom} src="/images/ventureforth2.png" alt="Venture Forth screenshot" />
                      <img className={styles.top} src="/images/ventureforth1.png" alt="Venture Forth screenshot" />
                    </div>
                  </a>
                  <div>
                    <h2><a href="https://ericanorby.github.io/Venture_Forth/" rel="noreferrer noopener" target="_blank">Venture Forth</a></h2>
                    <a href="https://github.com/ericanorby/Venture_Forth" rel="noreferrer noopener" target="_blank">GitHub repository</a>
                  </div>
                  <p className={styles.tech}>AngularJS, Ruby on Rails, JS & jQuery, HTML, CSS</p>
                  <p>Designed for travelers, this application allows users to search for a location, browse activities at that location, and add new activities. This was a group project and I enjoyed working with a team. The app includes use of third-party APIs.</p>
                </div>*/}

              </div>
            </div>

          </main>

          <Footer />

        </div>
      )}
    </ThemeContext.Consumer>
  )
}
